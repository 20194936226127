import React from "react";
import { Button, Grid } from "@mui/material";
import Element from "../Form/Element";
import { inputType } from "../../utils/enum";
import {
  certificationNameType,
  certificationType,
} from "../../utils/options.utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MobileInput from "../Form/MobileInput";
import useForm from "../../hooks/useForm";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { createCertificationThunk } from "../../store/slices/vendor/vendor.slice";
import { certificationValidation } from "../../utils/validations/vendor.validation";

const CertificationsModal = ({ singleCertification, handleClose }) => {
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    const formData = new FormData();
  
    // Populate formData with the input data
    Object.keys(data).forEach((key) => {
      if (key === "number") {
        formData.append("number", data[key]?.mobile_number);
        formData.append("code", data[key]?.country_code);
      } else if (key === "valid_to") {
        formData.append("valid_to", moment(data[key]).format("YYYY-MM-DD"));
      } else {
        formData.append(key, data[key]);
      }
    });
  
    if (singleCertification) {
      // New formData for past data to send in the API
      const pastData = new FormData();
      Object.keys(singleCertification).forEach((key) => {
        if (key === "number") {
          pastData.append("number", singleCertification[key]?.mobile_number);
          pastData.append("code", singleCertification[key]?.country_code);
        } else if (key === "valid_to") {
          pastData.append(
            "valid_to",
            moment(singleCertification[key]).format("YYYY-MM-DD")
          );
        } else {
          pastData.append(key, singleCertification[key]);
        }
      });
  
      // Dispatch the thunk with the formData and pastData
      dispatch(
        createCertificationThunk({
          id: singleCertification?.id,
          data: formData,
          pastData: pastData,
        })
      )
        .then(handleClose)
        .catch((error) => {
          console.error("Error creating certification:", error);
        });
    } else {
      dispatch(createCertificationThunk({ data: formData }))
        .then(handleClose)
        .catch((error) => {
          console.error("Error creating certification:", error);
        });
    }
  };
  

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useForm({
      initialValues: {
        name: singleCertification?.name,
        number: {
          country_code: singleCertification?.code,
          mobile_number: singleCertification?.number,
        },
        valid_to: singleCertification?.valid_to
          ? new Date(singleCertification?.valid_to)
          : new Date(),
        type: singleCertification?.type,
        file: singleCertification?.file,
      },
      onSubmit: onSubmit,
      validationSchema: certificationValidation,
    });

  return (
    <Grid item md={12}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} p={3}>
          <Grid item md={12} pb={0}>
            <Element
              label="Name"
              inputProps={{
                name: "name",
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              value={values.name}
              options={certificationNameType}
              errorText={touched.name && errors.name}
              eletype={inputType.select}
              icons={
                <ArrowDropDownIcon
                  sx={{ position: "absolute", right: 20, bottom: 24 }}
                />
              }
            />
          </Grid>
          <Grid item md={12} p={0}>
            <Element
              label="Type"
              inputProps={{
                name: "type",
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              value={values.type}
              options={certificationType}
              errorText={touched.type && errors.type}
              eletype={inputType.select}
              icons={
                <ArrowDropDownIcon
                  sx={{ position: "absolute", right: 20, bottom: 24 }}
                />
              }
            />
          </Grid>
          <Grid item md={12} p={0}>
            <MobileInput
              name="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.number}
              errorText={touched.number && errors.number}
              label="Mobile No"
            />
          </Grid>
          <Grid item md={12} p={0}>
            <Element
              label="Valid To"
              inputProps={{
                placeholder: "Please Enter Date",
                type: "text",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "valid_to",
              }}
              eletype={inputType.date}
              value={values.valid_to}
              errorText={touched.valid_to && errors.valid_to}
            />
          </Grid>
          <Grid item md={12} pb={2}>
            <Element
              label="Certificate File"
              inputProps={{
                type: "file",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "file",
              }}
              eletype={inputType.file}
              value={values.pow_files}
              errorText={touched.file && errors.file}
              fileUploadWithoutUrl={true}
            />
          </Grid>

          <Grid item md={12} p={0}>
            <Button type="submit" variant="contained" sx={{ float: "right" }}>
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default CertificationsModal;

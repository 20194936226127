import * as React from "react";
import {
  Button,
  Box,
  Card,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from "@mui/material";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import Modal from "../../components/Modal/Modal";
import { useState, useEffect } from "react";
import AddressModal from "../../components/Modal/AddressModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllAddressThunk,
  deleteBusinessAddress,
} from "../../store/slices/vendor/vendor.slice";
import ListHead from "../../components/common/ListHead";
import Scrollbar from "../../components/common/Scrollbar";
import useBusinessId from "../../hooks/useBusinessId";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import DeleteAction from "../../components/common/DeleteAction";

const TABLE_HEAD = [
  { id: "address_line1", label: "Address Line 1", alignRight: false },
  { id: "address_line2", label: "Address Line 2", alignRight: false },
  { id: "city", label: "City", alignRight: false },
  { id: "state", label: "State", alignRight: false },
  { id: "country", label: "Country", alignRight: false },
  { id: "zip", label: "Zip Code", alignRight: false },
  { id: "action", label: "Action ", alignRight: false },
];

export default function DataGridDemo() {
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
    setSelectedData(null);
  };
  const dispatch = useDispatch();
  const businessId = useBusinessId();

  const { allAddress, totalAddress } = useSelector(({ vendor }) => vendor);

  // --new for pagination starts here---

  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);

  const handleChangePage = (_, newPage) => {
    dispatch(fetchAllAddressThunk(newPage + 1));
    setPage(newPage);
  };

  // --new for pagination ends here---

  useEffect(() => {
    dispatch(fetchAllAddressThunk());
  }, [dispatch, businessId]);

  useEffect(() => {
    if (page != 0) {
      dispatch(fetchAllAddressThunk(page));
      setPage(parseInt(page - 1));
    }
  }, [allAddress.length == 0]);

  function handleEdit(row) {
    if (allAddress?.[0].profile_stage !== "sent_for_accounts_approval") {
      setSelectedData(row);
      setShowModal(true);
    }
  }

  function handleDelete(row) {
    // dispatch(deleteBusinessAddress({ id: id, page: 1 }));
    
    //new update to send all the data to the api
    dispatch(deleteBusinessAddress({data: row, page: 1}));
  }

  function getEmptyScreen() {
    return (
      <div style={{ padding: "3rem" }}>
        <SearchNotFound
          src="/static/not-found/no_office_address.png"
          subtitle="No address yet."
          title=" "
        />
      </div>
    );
  }

  return (
    <>
      <div style={{ height: 400, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            marginBottom: "30px",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleOpen}
          >
            Add Address
          </Button>
        </Box>
        {allAddress?.length === 0 ? (
          getEmptyScreen()
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    headLabel={TABLE_HEAD}
                    rowCount={allAddress?.length}
                    styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                  />
                  <TableBody>
                    {allAddress?.map((row) => {
                      const {
                        id,
                        address_line1,
                        address_line2,
                        city,
                        state,
                        country,
                        zip_code,
                      } = row;

                      return (
                        <TableRow
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell
                            onClick={() => handleEdit(row)}
                            align="left"
                          >
                            {address_line1}
                          </TableCell>
                          <TableCell
                            onClick={() => handleEdit(row)}
                            align="left"
                          >
                            {address_line2}
                          </TableCell>
                          <TableCell
                            onClick={() => handleEdit(row)}
                            align="left"
                          >
                            {city}
                          </TableCell>
                          <TableCell
                            onClick={() => handleEdit(row)}
                            align="left"
                          >
                            {state}
                          </TableCell>
                          <TableCell
                            onClick={() => handleEdit(row)}
                            align="left"
                          >
                            {country}
                          </TableCell>
                          <TableCell
                            onClick={() => handleEdit(row)}
                            align="left"
                          >
                            {zip_code}
                          </TableCell>
                          <TableCell align="left">
                            <DeleteAction
                              handleDelete={() => handleDelete(row)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        count={totalAddress}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={false}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        )}
      </div>
      <Modal
        isOpen={showModal}
        closeModal={handleClose}
        title="Add Address"
        content={
          <AddressModal
            data={selectedData}
            closeModal={handleClose}
            page={page == 0 ? 1 : page + 1}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
    </>
  );
}

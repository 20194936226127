import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal/Modal";
import Scrollbar from "../../components/common/Scrollbar";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import Founder from "./Founder";
import { useDispatch, useSelector } from "react-redux";
//   import {
//     deleteFounderProfileThunk,
//     fetchFounderProfileThunk,
//   } from "../store/slices/vendor/vendor.slice";
import EditIcon from "@mui/icons-material/Edit";
import DeleteAction from "../../components/common/DeleteAction";
import {
  deleteFounderProfileThunk,
  fetchFounderProfileThunk,
} from "../../store/slices/vendor/vendor.slice";

const TABLE_HEAD = [
  { label: "Type", id: "type", alignRight: false },
  { label: "Name", id: "name", alignRight: false },
  { label: "Email", id: "email", alignRight: false },
  { label: "Mobile Number", id: "number", alignRight: false },
  { label: "Linkedin Profile", id: "linkedin_url", alignRight: false },
  { label: "College", id: "college", alignRight: false },
  { label: "Action", id: "Action", alignRight: false },
];

const FounderprofileTable = () => {
  const [showModal, setShowModal] = useState(false);
  // --new for pagination starts here---
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [singleFounderData, setSingleFounderData] = useState(null);

  const dispatch = useDispatch();
  const { founderInfoProfileList, totalNumberProfileList } = useSelector(
    ({ vendor }) => vendor
  );

  useEffect(() => {
    dispatch(fetchFounderProfileThunk());
  }, [dispatch]);
  const handleModalPhase = () => {
    setShowModal(!showModal);
  };
  const handleEdit = (row) => {
    setShowModal(true);
    setSingleFounderData(row);
  };

  const handleChangePage = (_, newPage) => {
    dispatch(fetchFounderProfileThunk(newPage + 1));
    setPage(newPage);
  };

  const handleDelete = (id) => {
    const currentData = founderInfoProfileList.find((d) => d.id === id);
    dispatch(
      deleteFounderProfileThunk({ id, page: 1, current_data: currentData })
    );
    setPage(0);
  };

  const handleClose = () => {
    setShowModal(false);
    setSingleFounderData(null);
  };
  return (
    <>
      <div style={{ height: 400, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            marginBottom: "30px",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            onClick={handleModalPhase}
            startIcon={<Icon icon={plusFill} />}
          >
            Add Founder Profile
          </Button>
        </Box>

        <Box
          sx={{
            height: "100%",
            width: 1,
            "& .table-header": {
              backgroundColor: "rgb(244, 246, 248)",
              color: "rgb(99, 115, 129)",
            },
          }}
        >
          <Card>
            <Scrollbar>
              {founderInfoProfileList?.length > 0 ? (
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead sx={{ backgroundColor: "rgb(243, 246, 248)" }}>
                      {TABLE_HEAD.map((column) =>
                        column.id != "action" ? (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              top: 57,
                              minWidth: column.minWidth,
                              backgroundColor: "#f3f6f8",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: "#f3f6f8",
                              position: "sticky",
                              right: 0,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        )
                      )}
                    </TableHead>
                    <TableBody>
                      {founderInfoProfileList?.length === 0
                        ? []
                        : founderInfoProfileList.map((row) => {
                            return (
                              <TableRow
                                hover
                                key={row.id}
                                tabIndex={-1}
                                role="checkbox"
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell align="left">{row.type}</TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell align="left">{row.number}</TableCell>
                                <TableCell align="left">
                                  {row.linkedin_url}
                                </TableCell>
                                <TableCell align="left">
                                  {row.college}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <IconButton color="success" component="span">
                                    <EditIcon
                                      color="success"
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => handleEdit(row)}
                                    />
                                  </IconButton>
                                  <IconButton color="error" component="span">
                                    <DeleteAction
                                      handleDelete={() => handleDelete(row.id)}
                                    />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={totalNumberProfileList}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPageOptions={false}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              ) : (
                <SearchNotFound
                  src="/static/support.svg"
                  subtitle="There is currently no Founder Data to show."
                />
              )}
            </Scrollbar>
          </Card>
        </Box>
      </div>

      <Modal
        isOpen={showModal}
        closeModal={handleClose}
        title="Founder Info"
        content={
          <Founder
            closeModal={handleClose}
            singleFounderData={singleFounderData}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
    </>
  );
};

export default FounderprofileTable;

import {
  getAllPortfoliosApi,
  portfolioApi,
  addUploadPortfolioForPortfolio,
  deletePortfolio,
  addPortfolio,
  getAllPastWorkApi,
  getTechDataApi,
  pastworkApi,
} from "../../../utils/apis.utils";
import { requestTypeEnum, tabsNameEnum } from "../../../utils/enum";
import { createQueryString, showToastAlert } from "../../../utils/global/global";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";

import { getPortfolioThunk } from "../portfolio/portfolio.slice";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { fetchPastworkThunk } from "../vendor/vendor.slice";

export const addPortfolioHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(portfolioApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getPortfolioHandler = async (__, thunkApi) => {
  try {
     
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(getAllPortfoliosApi);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const addUploadPortfolioHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(addUploadPortfolioForPortfolio, data);

    // const { check } = thunkApi.extra.apiService;

    // const response = await check.post(addUploadPortfolioForPortfolio, {
    //   data:{
    //     data: data,
    //   },
    //   meta: tabsNameEnum.CorporateDeck,
    //   type: requestTypeEnum.new,
    // });

    thunkApi.dispatch(stopDashboardLoader());

    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopDashboardLoader());
    // thunkApi.dispatch(showFailureToast({ message: err }));
    showToastAlert(err, thunkApi);
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const fetchAllPortfolioHandler = async (__, thunkApi) => {
  thunkApi.dispatch(startDashboardLoader());
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(addUploadPortfolioForPortfolio);
  thunkApi.dispatch(stopDashboardLoader());
  return response.data;
};

export const deletePortfolioHandler = async (id, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.delete(deletePortfolio + "/" + id);
    thunkApi.dispatch(stopDashboardLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(getPortfolioThunk());
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopDashboardLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const updatePortfolioHandler = async ({ id, data }, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(addPortfolio + "/" + id, { data });
    thunkApi.dispatch(stopDashboardLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(getPortfolioThunk());
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopDashboardLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};


//past work handler
export const fetchPastworkHandler = async (page = 1, thunkApi) => {
  const query = createQueryString({
    page,
  });
  const { profile } = thunkApi.extra.apiService;
  const response = await profile.get(getAllPastWorkApi + query);
  return {
    data: response.data,
    total: response.total,
  };
};

export const fetchTechDataHandler = async (rb_type, thunkApi) => {
  const query = createQueryString({
    rb_type,
  });
  const { profile } = thunkApi.extra.apiService;
  const response = await Promise.all([
    profile.get(getTechDataApi + query),
    profile.get(getTechDataApi + query), // Adjust this to the actual endpoint for backend data
  ]);
  return {
    frontend: response?.[0]?.data,
    backend: response?.[1]?.data,
  };
};

export const createPastworkHandler = async ({ data, id }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    var response = null;
    if (id) {
      response = await profile.put(`${pastworkApi}/${id}`, data);
    } else {
      response = await profile.post(pastworkApi, data);
    }
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchPastworkThunk(data.page));
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const deletePastworkHandler = async (id, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.delete(pastworkApi + "/" + id);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchPastworkThunk());
    return id;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const updatePastworkHandler = async ({ id, data }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.put(pastworkApi + "/" + id, { data: data });
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchPastworkThunk());
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};
import React from "react";
import { inputType } from "../../utils/enum";
import { useStyles } from "./Form.theme";
import Switch from "./Switch";
import Input from "./Input";
import Dropdown from "./Dropdown";
import DatePicker from "react-datepicker";
import FileInput from "./FileInput";
import FileInputSupport from "./FileInputSupport";
import NumberInput from "./NumberInput";
import SelectInput from "./SelectInput";
import UrlInput from "./UrlInput";
import CheckboxList from "./CheckBoxGroup";
import ChipInput from "./ChipInput";

function Element({
  name,
  onChange,
  accept,
  eletype,
  label,
  errorText,
  inputProps,
  options,
  value,
  styles,
  bottom,
  icon,
  placeholder,
  resetTheField,
  disabled,
  fileUploadWithoutUrl,
  ...rest
}) {
  const classes = useStyles();

  function renderInput() {
    return (
      <Input
        label={label}
        placeholder={placeholder}
        errorText={errorText}
        inputProps={inputProps}
        value={value}
        styles={styles}
      />
    );
  }
  function renderSelectInput() {
    return (
      <SelectInput
        label={label}
        errorText={errorText}
        inputProps={inputProps}
        value={value}
      />
    );
  }
  function renderTextarea() {
    return (
      <div className={classes.formGroup} style={styles}>
        <label className={classes.formLabel}>{label}</label>
        <textarea
          className={classes.textareaFormControl}
          type="text"
          cols="4"
          rows="6"
          {...inputProps}
          value={value}
          autoComplete="off"
          placeholder={placeholder}
        />
        {errorText && (
          <span className={classes.formErrorStyle}>{errorText}</span>
        )}
      </div>
    );
  }

  function renderNumberInput() {
    return (
      <NumberInput
        label={label}
        errorText={errorText}
        inputProps={inputProps}
        value={value}
        {...rest}
      />
    );
  }

  function renderSelect() {
    return (
      <Dropdown
        label={label}
        errorText={errorText}
        options={options}
        inputProps={inputProps}
        value={value}
        styles={styles}
        disabled={disabled}
        resetTheField={resetTheField}
        {...rest}
      />
    );
  }

  function renderUrlInput() {
    return (
      <UrlInput
        label={label}
        errorText={errorText}
        inputProps={inputProps}
        value={value}
        style={styles}
        {...rest}
      />
    );
  }

  function renderAutoComplete() {
    return (
      <div className={classes.formGroup}>
        <label className={classes.formLabel}>Give your project name here</label>
        <input className={classes.formControl} type="text" autoComplete="off" />
      </div>
    );
  }
  function renderCheckBoxGroup() {
    return (
      <CheckboxList
        label={label}
        {...inputProps}
        errorText={errorText}
        value={value} 
        options={options}
        onChange={onChange}
        name={name}
      />
    );
  }

  function renderChip() {
    return (
      // <div className={classes.formGroup}>
      //   <label className={classes.formLabel}>Give your project name here</label>
      //   <input className={classes.formControl} type="text" autoComplete="off" />
      // </div>
      <ChipInput
        label={label}
        {...inputProps}
        value={value}
      />
    );
  }

  function renderDatePicker() {
    return (
      <>
        <div className={classes.formGroup}>
          <label className={classes.formLabel}>{label}</label>
          <DatePicker
            className={classes.formControl}
            selected={value}
            {...inputProps}
            {...rest}
            onChange={(date) => {
              inputProps.onChange({
                target: {
                  name: inputProps.name,
                  value: date,
                },
              });
            }}
            autoComplete="off"
            placeholderText={inputProps?.placeholder}
          />
          {errorText && (
            <span className={classes.formErrorStyle}>{errorText}</span>
          )}
        </div>
      </>
    );
  }
  return (
    <>
      {eletype === inputType.input && renderInput()}
      {eletype === inputType.selectInput && renderSelectInput()}
      {eletype === inputType.number && renderNumberInput()}
      {eletype === inputType.select && renderSelect()}
      {eletype === inputType.textarea && renderTextarea()}
      {eletype === inputType.autocomplete && renderAutoComplete()}
      {eletype === inputType.url && renderUrlInput()}
      {eletype === inputType.date && renderDatePicker()}
      {eletype === inputType.checkboxgroup && renderCheckBoxGroup()}
      {eletype === inputType.chip && renderChip()}
      {eletype === inputType.switch && (
        <Switch label={label} {...inputProps} {...rest} value={value} />
      )}
      {eletype === inputType.file && (
        <FileInput
        label={label}
        {...inputProps}
        errorText={errorText}
        value={value}
        {...rest}
        disabled={disabled}
        fileUploadWithoutUrl={fileUploadWithoutUrl}
      />
      )}
      {eletype === inputType.fileSupport && (
        <FileInputSupport
          label={label}
          {...inputProps}
          errorText={errorText}
          value={value}
          {...rest}
        />
      )}
    </>
  );
}

export default Element;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addCVsHandler,
  attachCVsHandler,
  fetchAllCVsHandler,
  fetchAllRALeadsHandler,
  fetchAllRALeadsPaginationHandler,
  fetchRaLeadByIdHandler,
  putNotInterestedHandler,
} from "./ra.thunk";

const INIT_STATE = {
  leads: {
    data: [],
  },
  lead: {},
  cvs: [],
};

export const fetchAllRALeadsThunk = createAsyncThunk(
  "fetchAllRALeads",
  fetchAllRALeadsHandler
);

export const fetchAllRALeadsWithPaginationThunk = createAsyncThunk(
  "fetchAllRALeadsPagination",
  fetchAllRALeadsPaginationHandler
);

export const fetchRaLeadByIdThunk = createAsyncThunk(
  "fetchRaLeadById",
  fetchRaLeadByIdHandler
);

export const fetchAllCVsThunk = createAsyncThunk(
  "fetchAllCVs",
  fetchAllCVsHandler
);
export const addCVsThunk = createAsyncThunk("addCVs", addCVsHandler);
export const attachCVsThunk = createAsyncThunk("attachCVs", attachCVsHandler);

export const putNotInterestedthunk = createAsyncThunk("putNotInterested", putNotInterestedHandler);

const raSlice = createSlice({
  name: "ra",
  initialState: INIT_STATE,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRALeadsThunk.fulfilled, (state, action) => {
        state.leads = action.payload;
        return state;
      })
      .addCase(
        fetchAllRALeadsWithPaginationThunk.fulfilled,
        (state, action) => {
          state.leads = action.payload;
          return state;
        }
      )
      .addCase(fetchRaLeadByIdThunk.fulfilled, (state, action) => {
        state.lead = action.payload;
        return state;
      })
      .addCase(fetchAllCVsThunk.fulfilled, (state, action) => {
        state.cvs = action.payload;
        return state;
      })
      .addCase(addCVsThunk.fulfilled, (state, action) => {
        state.cvs = [...state.cvs, action.payload];
        return state;
      })
      // check for response and update the state - todo
      .addCase(attachCVsThunk.fulfilled, (state, action) => {
        state.cvs = [...state.cvs, action.payload];
        return state;
      });
  },
});

export default raSlice.reducer;

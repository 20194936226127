import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCVsThunk,
  fetchRaLeadByIdThunk,
} from "../../store/slices/ra/ra.slice";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Stack,
  Avatar,
  Paper,
  Button,
  TextField,
  IconButton,
  Input,
  Container,
} from "@mui/material";
import Page from "../../components/Page";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { makeStyles } from "@mui/styles";
import Modal from "../../components/Modal/Modal";
import UploadCvs from "../FormData/UploadCvs";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import CVTable from "./CVTable";
import AttachCV from "../FormData/AttachCV";

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    minWidth: 256,
    textAlign: "center",
  },
  portfolioHeader: {
    "& span": {
      lineHeight: 1.3,
      height: "40px",
    },
  },
  avatar: {
    width: 60,
    height: 60,
    margin: "auto",
  },
  heading: {
    fontSize: 18,
    fontWeight: "bold",
    letterSpacing: "0.5px",
    marginTop: 8,
    marginBottom: 0,
  },
  subheader: {
    fontSize: 14,
    color: "gray",
    marginBottom: "0.875em",
  },
  statLabel: {
    fontSize: 12,
    color: "gray",
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 4,
    letterSpacing: "1px",
  },
  borderedGridStyles: {
    borderColor: "rgba(0, 0, 0, 0.08)",
    textAlign: "center",
  },
  "@media (max-width:1000px)": {
    cardInMobile: {
      paddingLeft: "40px !important",
      paddingRight: "20px",
    },
  },
  "@media (max-width:550px)": {
    cardInMobile: {
      margin: 15,
      padding: 0,
      paddingLeft: "30px !important",
    },
    cardParent: {
      display: "-webkit-inline-box",
    },
    centerDiv: {
      display: "flex !important",
      justifyContent: "center !important",
    },
  },
}));

function RaCvs() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const lead = useSelector(({ ra }) => ra.lead);
  const cvs = useSelector(({ ra }) => ra.cvs);
  useEffect(() => {
    dispatch(fetchRaLeadByIdThunk(params.leadId));
  }, []);

  useEffect(() => {
    if (lead._id) dispatch(fetchAllCVsThunk(lead._id));
  }, [lead._id]);

  const [open, setOpen] = useState(false);

  function showCvModal() {
    setOpen(true);
  }

  function hideCvModal() {
    setOpen(false);
  }
  const [openAttachCvModal, setOpenAttachCvModal] = useState(false);

  function showAttachCvModal() {
    setOpenAttachCvModal(true);
  }

  function hideAttachCvModal() {
    setOpenAttachCvModal(false);
  }

  return (
    <>
      <Page title="Portfolio | EMB">
        <Container>
          <Grid container spacing={4} px={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={4}
                py={3}
              >
                <Grid md={6}>Upload Cvs</Grid>
                <Grid md={6}>
                  <Stack
                    mr={3}
                    direction="row"
                    spacing={2}
                    sx={{
                      marginLeft: { sm: "23px", xs: "23px" },
                      float: "right",
                    }}
                  >
                    <Button
                      variant="contained"
                      to="/dashboard/user/createUser"
                      startIcon={<Icon icon={plusFill} />}
                      onClick={showCvModal}
                    >
                      Add Cvs
                    </Button>
                    <Button
                      variant="outlined"
                      to="/dashboard/user/createUser"
                      startIcon={<Icon icon={plusFill} />}
                      onClick={showAttachCvModal}
                    >
                      Attach CV
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* ------------------ UploadedCvsdata ----------÷ */}
          <Box p={2}>
            <Stack
              direction="row"
              spacing={5}
              sx={{
                marginTop: "30px",
                marginBottom: "20px",
              }}
            >
              <Grid
                item
                sx={{
                  marginTop: "20px",
                  fontSize: "24px",
                  color: "#343434",
                  fontWeight: "bold",
                }}
                xs={12}
                md={12}
                className={classes.centerDiv}
              ></Grid>
            </Stack>

            <CVTable cvs={cvs} />

            <Stack direction="row" spacing={5}>
              {/* <Grid container spacing={2}>
                {uploadPortfolioData &&
                  uploadPortfolioData.map((data) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.centerDiv}
                    >
                      <a
                        href={data.url}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Card sx={{ width: "300px", borderRadius: "10px" }}>
                          <Box p={3}>
                            <Stack direction="row" spacing={2}>
                              <Avatar
                                sx={{ bgcolor: "#eff8f1" }}
                                variant="rounded"
                              >
                                {data.media_type === "pdf" ? (
                                  <PictureAsPdfIcon sx={{ color: "#de4435" }} />
                                ) : (
                                  <PhotoLibraryIcon sx={{ color: "#00c9a7" }} />
                                )}
                              </Avatar>
                              <Box sx={{ width: "160px" }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: "500" }}
                                >
                                  {data.filename?.length > 15
                                    ? data.filename.substring(0, 15) + "..."
                                    : data.filename}
                                </Typography>
                              </Box>
                              <Avatar
                                sx={{ bgcolor: "#f3f6f8", color: "#637281" }}
                              >
                                S
                              </Avatar>
                            </Stack>
                          </Box>
                        </Card>
                      </a>
                    </Grid>
                  ))}
              </Grid> */}
            </Stack>
          </Box>
          {/* -------------------------- ENDS HERE ---------------- */}
        </Container>
      </Page>
      <Modal
        isOpen={open}
        closeModal={() => {
          hideCvModal();
        }}
        content={
          <UploadCvs
            sx={{ marginTop: "20px" }}
            hidePortfolioModalCheck={hideCvModal}
            raId={lead?._id}
          />
        }
        contentStyle={{ minWidth: "400px" }}
        ac
      />
      <Modal
        isOpen={openAttachCvModal}
        closeModal={() => {
          hideAttachCvModal();
        }}
        content={
          <AttachCV
            sx={{ marginTop: "20px" }}
            hidePortfolioModalCheck={hideAttachCvModal}
            raId={lead?._id}
          />
        }
        contentStyle={{ minWidth: "400px" }}
        maxWidth={'xl'}
      />
    </>
  );
}

export default RaCvs;

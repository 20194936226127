import { create, get } from "lodash";
import {
  fetchAllDeliveryClassApi,
  fetchAllIndustriesApi,
  fetchAllProductCodeListApi,
  fetchAllServicesApi,
  fetchResourceListApi,
  fetchSubIndustryApi,
  getResourceBifurcationListApi,
  getTechStackListApi,
} from "./apis.utils";
import { vendorAxios } from "./axios/vendor.axios";
import { createQueryString } from "./global/global";

export const getResouceBifercationList = async () => {
  const response = await vendorAxios.get(getResourceBifurcationListApi);
  return response.data;
};

export const getTechStackList = async (id) => {
  const response = await vendorAxios.get(getTechStackListApi + `?rb_id=${id}`);
  return response.data;
};

export const getAllProductListData = async () => {
  const response = await vendorAxios.get(fetchAllProductCodeListApi);
  return response.data;
};

export const fetchResourceBiferctionList = async (term) => {
  const query = createQueryString({
    rb_type: term,
  });

  const response = await vendorAxios.get(fetchResourceListApi + query);
  return response.data;
};

export const fetchAllResourceBiFurcationList = async () => {
  try {
    function firstRequest() {
      return vendorAxios.get(fetchResourceListApi + "?rb_type=frontend");
    }

    function secondRequest() {
      const query = createQueryString({
        rb_type: "backend",
      });
      return vendorAxios.get(fetchResourceListApi + query);
    }

    function thirdRequest() {
      const query = createQueryString({
        rb_type: "database",
      });
      return vendorAxios.get(fetchResourceListApi + query);
    }

    function fourthRequest() {
      const query = createQueryString({
        rb_type: "devops",
      });
      return vendorAxios.get(fetchResourceListApi + query);
    }

    const response = await Promise.all([
      firstRequest(),
      secondRequest(),
      thirdRequest(),
      fourthRequest(),
    ]);

    return {
      frontend: response?.[0]?.data,
      backend: response?.[1]?.data,
      database: response?.[2]?.data,
      cloud: response?.[3]?.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const fetchAllServices = async () => {
  const response = await vendorAxios.get(fetchAllServicesApi);
  return response.data;
};

export const fetchAllIndustries = async () => {
  const response = await vendorAxios.get(fetchAllIndustriesApi);
  return response.data;
};

export const fetchAllDeliveryClass = async () => {
  const response = await vendorAxios.get(fetchAllDeliveryClassApi);
  return response.data;
};

export const fetchSubIndustry = async (id) => {
  const response = await vendorAxios.get(fetchSubIndustryApi + "/" + id);
  return response.data;
};

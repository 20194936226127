import { Box, Button, Container, Grid } from "@mui/material";
import React from "react";
import Element from "../../components/Form/Element";
import useForm from "../../hooks/useForm";
import { useDispatch } from "react-redux";
import { inputType } from "../../utils/enum";
import { founderOptions } from "../../utils/options.utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import { founderProfileInfoValidation } from "../utils/validations/vendor.validation";
import MobileInput from "../../components/Form/MobileInput";
import { createFounderProfileThunk } from "../../store/slices/vendor/vendor.slice";
import { founderProfileInfoValidation } from "../../utils/validations/vendor.validation";
// import { createFounderProfileThunk } from "../store/slices/vendor/vendor.slice";

const Founder = ({ closeModal, singleFounderData }) => {
  // dispatch the function
  const dispatch = useDispatch();

  // submit form input
  const onSubmit = (data) => {
    const newData = {
      ...data,
      code: data?.founder_mobile_no?.country_code,
      number: data?.founder_mobile_no?.mobile_number,
    };
    delete newData?.founder_mobile_no;
    if (singleFounderData) {
      dispatch(
        createFounderProfileThunk({
          id: singleFounderData?.id,
          data: newData,
          pastData: singleFounderData,
        })
      )
        // .unwrap()
        .then(closeModal);
    } else {
      dispatch(createFounderProfileThunk({ data: newData }))
        // .unwrap()
        .then(closeModal);
    }
  };
  const { name, type, number, email, linkedin_url, college,code } =
    singleFounderData ?? {};
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useForm({
      initialValues: {
        name: name,
        founder_mobile_no: {
          country_code:code ,
          mobile_number: number,
        },
        email: email,
        linkedin_url: linkedin_url,
        type: type,
        college: college,
      },
      onSubmit: onSubmit,
      validationSchema: founderProfileInfoValidation,
    });
  return (
    <Container>
      <Box>
        <Grid container spacing={2} mt={5}>
          <Grid item md={12}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} p={3}>
                <Grid item md={12} p={0}>
                  <Element
                    label="Type*"
                    inputProps={{
                      name: "type",
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={values.type}
                    options={founderOptions}
                    errorText={touched.type && errors.type}
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12} p={0}>
                  <Element
                    label="Name*"
                    inputProps={{
                      placeholder: "Enter Founder Name",
                      type: "text",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: "name",
                    }}
                    eletype={inputType.input}
                    value={values.name}
                    errorText={touched.name && errors.name}
                  />
                </Grid>

                <Grid item md={12} p={0}>
                  <Element
                    label="Email*"
                    inputProps={{
                      placeholder: "Please Enter Date",
                      type: "text",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: "email",
                    }}
                    eletype={inputType.input}
                    value={values.email}
                    errorText={touched.email && errors.email}
                  />
                </Grid>

                <Grid item md={12} p={0}>
                  <MobileInput
                    name="founder_mobile_no"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.founder_mobile_no}
                    label="Contact"
                  />
                </Grid>

                <Grid item md={12} p={0}>
                  <Element
                    label="Linkedin URL"
                    inputProps={{
                      placeholder: "Please Enter Linkedin Url",
                      type: "text",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: "linkedin_url",
                      linkedin: true,
                    }}
                    eletype={inputType.url}
                    value={values.linkedin_url}
                    errorText={touched.linkedin_url && errors.linkedin_url}

                  />
                </Grid>

                <Grid item md={12} p={0}>
                  <Element
                    label="Founder College"
                    inputProps={{
                      placeholder: "Please Enter Date",
                      type: "text",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: "college",
                    }}
                    eletype={inputType.input}
                    value={values.college}
                    errorText={touched.college && errors.college}
                  />
                </Grid>

                <Grid item md={12} p={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ float: "right" }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Founder;

import React from "react";
import {
  Typography,
  Container,
  Card,
  Box,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import "./upload.css";
// components
import { bankDetailsValidation } from "../../utils/validation";
import useForm from "../../hooks/useForm";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { useDispatch, useSelector } from "react-redux";
import { updateBusinessProfileThunk } from "../../store/slices/vendor/vendor.slice";
import useBusinessId from "../../hooks/useBusinessId";
import BankDetailLoader from "../../components/Skeletons/BankDetailLoader";

export default function BankDetails() {
  const businessId = useBusinessId();
  const dispatch = useDispatch();
  const { profileData } = useSelector(({ vendor }) => vendor);
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useForm({
      initialValues: {
        bank_name: profileData.bank_name ? profileData.bank_name : "",
        account_number: profileData.account_number
          ? profileData.account_number
          : "",
        ifsc_code: profileData.ifsc_code ? profileData.ifsc_code : "",
        bank_branch: profileData.bank_branch ? profileData.bank_branch : "",
        cancelled_cheque_file: profileData.cancelled_cheque_file
          ? profileData.cancelled_cheque_file
          : "",
      },
      onSubmit: onSubmit,
      validationSchema: bankDetailsValidation,
    });

  function onSubmit(data) {

    const pastData = {
      bank_name: profileData.bank_name,
      account_number: profileData.account_number,
      ifsc_code: profileData.ifsc_code,
      bank_branch: profileData.bank_branch,
      cancelled_cheque_file: profileData.cancelled_cheque_file,
    }

    dispatch(
      updateBusinessProfileThunk({
        id: businessId,
        data: data,
        past_data: pastData,
      })
    );
  }
  return (
    <Container>
      <Box>
        <Grid container spacing={2} mt={5}>
          <Grid item md={12}>
            <Card sx={{ minHeight: "420px", py: 2, px: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                m={2}
              >
                <Typography variant="h4" gutterBottom>
                  Bank Details
                </Typography>
              </Stack>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} p={3}>
                  <Grid item md={6} pb={2}>
                    <Element
                      label="Bank Name"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter Bank Name",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "bank_name",
                      }}
                      eletype={inputType.input}
                      value={values.bank_name}
                      errorText={touched.bank_name && errors.bank_name}
                    />
                  </Grid>

                  <Grid item md={6} p={3}>
                    <Element
                      label="Account Number"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter Account Number",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "account_number",
                      }}
                      eletype={inputType.input}
                      value={values.account_number}
                      errorText={
                        touched.account_number && errors.account_number
                      }
                    />
                  </Grid>

                  <Grid item md={6} p={3}>
                    <Element
                      label="IFSC Code"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter IFSC Code",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "ifsc_code",
                      }}
                      eletype={inputType.input}
                      value={values.ifsc_code}
                      errorText={touched.ifsc_code && errors.ifsc_code}
                    />
                  </Grid>

                  <Grid item md={6} p={3}>
                    <Element
                      label="Branch Name"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter Branch Name",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "bank_branch",
                      }}
                      eletype={inputType.input}
                      value={values.bank_branch}
                      errorText={touched.bank_branch && errors.bank_branch}
                    />
                  </Grid>
                  <Grid item md={6} p={3}>
                    <Element
                      label="Upload Cancel cheque"
                      inputProps={{
                        type: "text",

                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "cancelled_cheque_file",
                      }}
                      extraArguments={{
                        upload_type: "cancelled_cheque_file",
                        vendor_profile_id: `${businessId}`,
                      }}
                      eletype={inputType.file}
                      value={values.cancelled_cheque_file}
                      errorText={
                        touched.cancelled_cheque_file &&
                        errors.cancelled_cheque_file
                      }
                    />
                  </Grid>

                  <Grid item md={12} p={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ float: "right" }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
            {/* <BankDetailLoader /> */}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

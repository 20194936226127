import React, { useEffect } from "react";
import { Button, Grid } from "@mui/material";
import Element from "../Form/Element";
import { inputType } from "../../utils/enum";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MobileInput from "../Form/MobileInput";
import useForm from "../../hooks/useForm";
// import { testimonialsValidation } from "../../utils/validations/vendor.validation";
import { useDispatch, useSelector } from "react-redux";
import {
  createTestimonialInfoThunk,
  fetchProductCodeOptionsThunk,
} from "../../store/slices/vendor/vendor.slice";
import { testimonialsValidation } from "../../utils/validations/vendor.validation";
// import { createTestimonialInfoThunk } from "../../store/slices/vendor/vendor.slice";

const TestimonialsModal = ({ singleTestimonialInfo, handleClose }) => {
  // submit form input
  const { productCodeOptionsData } = useSelector(({ vendor }) => vendor);
  const dispatch = useDispatch();
  var productCodeArr = productCodeOptionsData?.map((item) => ({
    label: item?.code,
    value: item?.id,
  }));

  const onSubmit = (data) => {
    const findProductCode = productCodeArr.find(
      (item) => item?.value === data?.product_code
    );
    const { label, value } = findProductCode ?? {};

    const newData = {
      ...data,
      code: data?.contact?.country_code,
      phone_number: data?.contact?.mobile_number,
      // product_code: { code: label, id: value },
      delivery_class: null,
    };
    delete newData?.contact;
    if (singleTestimonialInfo) {
      dispatch(
        createTestimonialInfoThunk({
          id: singleTestimonialInfo?.id,
          data: newData,
        })
      )
        .unwrap()
        .then(handleClose);
    } else {
      dispatch(createTestimonialInfoThunk({ data: newData }))
        .unwrap()
        .then(handleClose);
    }
  };

  useEffect(() => {
    dispatch(fetchProductCodeOptionsThunk());
  }, [dispatch]);

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useForm({
      initialValues: {
        spoc_name: singleTestimonialInfo?.spoc_name,
        contact: {
          country_code: singleTestimonialInfo?.code,
          mobile_number: singleTestimonialInfo?.phone_number,
        },
        email: singleTestimonialInfo?.email,
        company_name: singleTestimonialInfo?.company_name,
        service_delivered: singleTestimonialInfo?.service_delivered,
        // product_code: singleTestimonialInfo?.product_code,
        
      },
      onSubmit: onSubmit,
      validationSchema: testimonialsValidation,
    });

  return (
    <Grid item md={12}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} p={3}>
          <Grid item md={12} pb={0}>
            <Element
              label="SPOC Name*"
              inputProps={{
                placeholder: "Enter Brand Name",
                type: "text",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "spoc_name",
              }}
              eletype={inputType.input}
              value={values.spoc_name}
              errorText={touched.spoc_name && errors.spoc_name}
            />
          </Grid>
          <Grid item md={12} pb={0}>
            <Element
              label="Email*"
              inputProps={{
                placeholder: "Enter Brand Name",
                type: "text",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "email",
              }}
              eletype={inputType.input}
              value={values.email}
              errorText={touched.email && errors.email}
            />
          </Grid>
          <Grid item md={12} p={0}>
            <MobileInput
              name="contact"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.contact}
              errorText={touched.contact && errors.contact}
              label="Contact Number*"
            />
          </Grid>
          <Grid item md={12} pb={0}>
            <Element
              label="Company Name*"
              inputProps={{
                placeholder: "Enter Company Name",
                type: "text",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "company_name",
              }}
              eletype={inputType.input}
              value={values.company_name}
              errorText={touched.company_name && errors.company_name}
            />
          </Grid>
          <Grid item md={12} pb={0}>
            <Element
              label="Service Delivered*"
              inputProps={{
                placeholder: "Enter Service Delivered",
                type: "text",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "service_delivered",
              }}
              eletype={inputType.input}
              value={values.service_delivered}
              errorText={touched.service_delivered && errors.service_delivered}
            />
          </Grid>
          {/* <Grid item md={12} p={0}>
            <Element
              label="Product Code*"
              inputProps={{
                name: "product_code",
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              value={values.product_code}
              options={productCodeArr}
              errorText={touched.product_code && errors.product_code}
              eletype={inputType.select}
              icons={
                <ArrowDropDownIcon
                  sx={{ position: "absolute", right: 20, bottom: 24 }}
                />
              }
            />
          </Grid> */}

          <Grid item md={12} p={0}>
            <Button type="submit" variant="contained" sx={{ float: "right" }}>
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default TestimonialsModal;

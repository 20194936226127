// material
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Stack,
  Box,
} from "@mui/material";
// components
import Page from "../components/Page.js";
import { Outlet, useLocation } from "react-router-dom";
import { AppWeeklySales } from "../components/_dashboard/app";
import { green } from "@mui/material/colors";
import { fetchProjectMatrixThunk } from "../store/slices/dashboard/dashboard.slice.js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Icon } from "@iconify/react";
import ProfileCompletition from "../components/_dashboard/profile/ProfileCompletition.js";
import ReactTour from "../components/Tour/ReactTour.js";
// ----------------------------------------------------------------------

function DashboardApp() {
  const dispatch = useDispatch();
  const { isBusinessProfileExist } = useSelector(({ user }) => user);
  const { new_user } = useSelector(({ user }) => user.userData.profile);
  const { isProfileDataFetched } = useSelector(({ vendor }) => vendor);

  useEffect(() => {
    if (isBusinessProfileExist) {
      dispatch(fetchProjectMatrixThunk());
    }
  }, [dispatch, isBusinessProfileExist]);

  const metrics = useSelector(({ dashboard }) => dashboard.main);

  return (
    <>
      {new_user && isProfileDataFetched && <ReactTour />}
      <Outlet />
      <Page title="Dashboard | EMB">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={7}>
              <AppWeeklySales isBusinessProfileExist={isBusinessProfileExist} />
              {/* <AppWeeklyLoader /> */}
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              {/* <SimpleSlider /> */}
              {/* <WalletCard /> */}
              {/* <WalletLoader /> */}
              <ProfileCompletition />

              {/* <ProfileVideoIntro /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography component="h5" variant="h4" mt={3} p={1}>
                Analytics Overview
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  border: "2px solid #E4E8EB",
                  borderRadius: "26px",
                  boxShadow: "none",
                }}
              >
                <Grid container>
                  <Grid md={3}>
                    <img
                      src="/static/icons/total-projects.svg"
                      width="100"
                      height="100"
                      style={{
                        padding: "15px",
                      }}
                      alt=""
                    />
                  </Grid>
                  <Grid item md={9}>
                    <Stack
                      direction="row"
                      justifyContent="space-evenly"
                      spacing={2}
                    >
                      <Box>
                        <Box>
                          <CardHeader
                            title="Active Projects"
                            pt={2}
                            sx={{ marginLeft: "-30px" }}
                          />
                        </Box>
                        {isBusinessProfileExist ? (
                          <Typography
                            variant="h5"
                            sx={{
                              pl: 3,
                              color: green[800],
                              marginLeft: "-30px",
                            }}
                          >
                            {metrics.projects_count}
                          </Typography>
                        ) : (
                          <Box pt={0.2}>
                            <Typography
                              variant="h5"
                              sx={{
                                pl: 3,
                                color: green[800],
                                background: "#e5e9eb",
                                width: "100px",
                                borderRadius: "6px",
                                height: "30px",
                                marginRight: "20px",
                              }}
                            ></Typography>
                          </Box>
                        )}
                      </Box>
                      <Box>
                        {!isBusinessProfileExist && (
                          <img
                            src="/static/icons/lock.svg"
                            width="40"
                            height="40"
                            style={{
                              marginTop: "33px",
                            }}
                            alt=""
                          />
                        )}
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
              {/* <TotalProjectLoader /> */}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  border: "2px solid #E4E8EB",
                  borderRadius: "26px",
                  boxShadow: "none",
                }}
              >
                <Grid container>
                  <Grid md={3}>
                    <img
                      src="/static/icons/total-clients.svg"
                      width="100"
                      height="100"
                      style={{
                        padding: "15px",
                      }}
                      alt=""
                    />
                  </Grid>
                  <Grid item md={9}>
                    <Stack
                      direction="row"
                      justifyContent="space-evenly"
                      spacing={2}
                    >
                      <Box>
                        <Box>
                          <CardHeader
                            title="Closed Projects"
                            pt={2}
                            sx={{ marginLeft: "-30px" }}
                          />
                        </Box>
                        {isBusinessProfileExist ? (
                          <Typography
                            variant="h5"
                            sx={{
                              pl: 3,
                              color: green[800],
                              marginLeft: "-30px",
                            }}
                          >
                            {metrics.clients_count}
                          </Typography>
                        ) : (
                          <Box pt={0.2}>
                            <Typography
                              variant="h5"
                              sx={{
                                pl: 3,
                                color: green[800],
                                background: "#e5e9eb",
                                width: "100px",
                                borderRadius: "6px",
                                height: "30px",
                                marginRight: "20px",
                              }}
                            ></Typography>
                          </Box>
                        )}
                      </Box>
                      <Box>
                        {!isBusinessProfileExist && (
                          <img
                            src="/static/icons/lock.svg"
                            width="40"
                            height="40"
                            style={{
                              marginTop: "33px",
                            }}
                            alt=""
                          />
                        )}
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
              {/* <TotalProjectLoader /> */}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  border: "2px solid #E4E8EB",
                  borderRadius: "26px",
                  boxShadow: "none",
                }}
              >
                <Grid container>
                  <Grid md={3}>
                    <img
                      src="/static/icons/accepted-leads.svg"
                      width="100"
                      height="100"
                      style={{
                        padding: "15px",
                      }}
                      alt=""
                    />
                  </Grid>
                  <Grid item md={9}>
                    <Stack
                      direction="row"
                      justifyContent="space-evenly"
                      spacing={2}
                    >
                      <Box>
                        <Box>
                          <CardHeader
                            title="Accepted Lead"
                            pt={2}
                            sx={{ marginLeft: "-30px" }}
                          />
                        </Box>
                        {isBusinessProfileExist ? (
                          <Typography
                            variant="h5"
                            sx={{
                              pl: 3,
                              color: green[800],
                              marginLeft: "-30px",
                            }}
                          >
                            {metrics.accepted_leads_count}
                          </Typography>
                        ) : (
                          <Box pt={0.2}>
                            <Typography
                              variant="h5"
                              sx={{
                                pl: 3,
                                color: green[800],
                                background: "#e5e9eb",
                                width: "100px",
                                borderRadius: "6px",
                                height: "30px",
                                marginRight: "20px",
                              }}
                            ></Typography>
                          </Box>
                        )}
                      </Box>
                      <Box>
                        {!isBusinessProfileExist && (
                          <img
                            src="/static/icons/lock.svg"
                            width="40"
                            height="40"
                            style={{
                              marginTop: "33px",
                            }}
                            alt=""
                          />
                        )}
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
              {/* <TotalProjectLoader /> */}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  border: "2px solid #E4E8EB",
                  borderRadius: "26px",
                  boxShadow: "none",
                }}
              >
                <Grid container>
                  <Grid md={3}>
                    <img
                      src="/static/icons/reject-leads.svg"
                      width="100"
                      height="100"
                      style={{
                        padding: "15px",
                      }}
                      alt=""
                    />
                  </Grid>
                  <Grid item md={9}>
                    <Stack
                      direction="row"
                      justifyContent="space-evenly"
                      spacing={2}
                    >
                      <Box>
                        <Box>
                          <CardHeader
                            title="Rejected Lead"
                            pt={2}
                            sx={{ marginLeft: "-30px" }}
                          />
                        </Box>
                        {isBusinessProfileExist ? (
                          <Typography
                            variant="h5"
                            sx={{
                              pl: 3,
                              color: green[800],
                              marginLeft: "-30px",
                            }}
                          >
                            {metrics.rejected_leads_count}
                          </Typography>
                        ) : (
                          <Box pt={0.2}>
                            <Typography
                              variant="h5"
                              sx={{
                                pl: 3,
                                color: green[800],
                                background: "#e5e9eb",
                                width: "100px",
                                borderRadius: "6px",
                                height: "30px",
                                marginRight: "20px",
                              }}
                            ></Typography>
                          </Box>
                        )}
                      </Box>
                      <Box>
                        {!isBusinessProfileExist && (
                          <img
                            src="/static/icons/lock.svg"
                            width="40"
                            height="40"
                            style={{
                              marginTop: "33px",
                            }}
                            alt=""
                          />
                        )}
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
              {/* <TotalProjectLoader /> */}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  border: "2px solid #E4E8EB",
                  borderRadius: "26px",
                  boxShadow: "none",
                }}
              >
                <Grid container>
                  <Grid md={3}>
                    <img
                      src="/static/icons/pending-leads.svg"
                      width="100"
                      height="100"
                      style={{
                        padding: "15px",
                      }}
                      alt=""
                    />
                  </Grid>
                  <Grid item md={9}>
                    <Stack
                      direction="row"
                      justifyContent="space-evenly"
                      spacing={2}
                    >
                      <Box>
                        <Box>
                          <CardHeader
                            title="Pending Lead"
                            pt={2}
                            sx={{ marginLeft: "-30px" }}
                          />
                        </Box>
                        {isBusinessProfileExist ? (
                          <Typography
                            variant="h5"
                            sx={{
                              pl: 3,
                              color: green[800],
                              marginLeft: "-30px",
                            }}
                          >
                            {metrics.pending_leads_count}
                          </Typography>
                        ) : (
                          <Box pt={0.2}>
                            <Typography
                              variant="h5"
                              sx={{
                                pl: 3,
                                color: green[800],
                                background: "#e5e9eb",
                                width: "100px",
                                borderRadius: "6px",
                                height: "30px",
                                marginRight: "20px",
                              }}
                            ></Typography>
                          </Box>
                        )}
                      </Box>
                      <Box>
                        {!isBusinessProfileExist && (
                          <img
                            src="/static/icons/lock.svg"
                            width="40"
                            height="40"
                            style={{
                              marginTop: "33px",
                            }}
                            alt=""
                          />
                        )}
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
              {/* <TotalProjectLoader /> */}
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}

export default DashboardApp;

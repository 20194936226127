import {
    Box,
    Button,
    Card,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Typography,
    TableFooter,
    TablePagination,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
//   import {
//     deleteCertificationThunk,
//     fetchCertificationThunk,
//   } from "../store/slices/vendor/vendor.slice";
  import plusFill from "@iconify/icons-eva/plus-fill";
  import { Icon } from "@iconify/react";
  import Scrollbar from "../../components/common/Scrollbar";
  import SearchNotFound from "../../components/NotFound/SearchNotFound";
  import ListHead from "../../components/common/ListHead";
  import EditIcon from "@mui/icons-material/Edit";
  import DeleteAction from "../../components/common/DeleteAction";
  import Modal from "../../components/Modal/Modal";
  import CertificationsModal from "../../components/Modal/CertificationsModal";
import { deleteCertificationThunk, fetchCertificationThunk } from "../../store/slices/vendor/vendor.slice";
  
  const TABLE_HEAD = [
    { id: "id", label: "Id", alignRight: false },
    { id: "name", label: "Name", alignRight: false },
    { id: "type", label: "Type", alignRight: false },
    { id: "mobile", label: "Mobile No", alignRight: false },
    { id: "valid_to", label: "Valid To", alignRight: false },
    { id: "file", label: "File", alignRight: false },
    { id: "action", label: "Action", alignRight: false },
  ];
  
  const Certification = () => {
    //inital state of file
    const [singleCertification, setSingleCertification] = useState(null);
    // --new for pagination starts here---
    const [page, setPage] = React.useState(0);
    const [rowsPerPage] = React.useState(10);
  
    // dispatch the function
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(fetchCertificationThunk());
    }, [dispatch]);
    const { certification, certificationTotalNo } = useSelector(
      ({ vendor }) => vendor
    );
  
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      setOpen(false);
      setSingleCertification(null);
    };
  
    const handleEdit = (row) => {
      setSingleCertification(row);
      setOpen(true);
    };
    const handleDelete = (row) => {
      dispatch(deleteCertificationThunk({ id: row.id, currentData: row, page: 1 }));
      setPage(0);
    };
  
    const handleChangePage = (_, newPage) => {
      dispatch(fetchCertificationThunk(newPage + 1));
      setPage(newPage);
    };

    console.log(certification, ": certification")
  
    return (
      <>
        <div style={{ height: 400, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              marginBottom: "30px",
              justifyContent: "end",
            }}
          >
            <Button
              onClick={handleOpen}
              variant="contained"
              sx={{ float: "right" }}
              startIcon={<Icon icon={plusFill} />}
            >
              Add Certification
            </Button>
          </Box>
  
          <Box
            sx={{
              height: "100%",
              width: 1,
              "& .table-header": {
                backgroundColor: "rgb(244, 246, 248)",
                color: "rgb(99, 115, 129)",
              },
            }}
          >
            <Card>
              <Scrollbar>
                {certification?.length > 0 ? (
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <ListHead
                        headLabel={TABLE_HEAD}
                        rowCount={certification?.length}
                        styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                      />
                      <TableBody>
                        {certification?.map((row) => {
                          const { id, name, type, number, valid_to, file } = row;
                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              sx={{ cursor: "pointer" }}
                            >
                              <TableCell align="left">{id}</TableCell>
                              <TableCell align="left">{name}</TableCell>
                              <TableCell align="left">{type}</TableCell>
                              <TableCell align="left">{number}</TableCell>
                              <TableCell align="left">{valid_to}</TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                                onClick={() => window.open(file?.url, "Download")}
                              >
                                <Typography noWrap width={200}>
                                  {file?.filename ?? '-'}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <IconButton color="success" component="span">
                                  <EditIcon
                                    color="success"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => handleEdit(row)}
                                  />
                                </IconButton>
                                <IconButton color="error" component="span">
                                  <DeleteAction
                                    handleDelete={() => handleDelete(row)}
                                  />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            count={certificationTotalNo}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={false}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                ) : (
                  <SearchNotFound
                    src="/static/support.svg"
                    subtitle="There is currently no Certification Data to show."
                  />
                )}
              </Scrollbar>
            </Card>
          </Box>
        </div>
  
        <Modal
          isOpen={open}
          closeModal={handleClose}
          title="Certification Info"
          content={
            <CertificationsModal
              singleCertification={singleCertification}
              handleClose={handleClose}
            />
          }
          dialogProps={{ fullWidth: true }}
          titleStyle={{ textAlign: "center", marginTop: 2 }}
          actionStyle={{ justifyContent: "center", marginBottom: 2 }}
        />
      </>
    );
  };
  
  export default Certification;
  